import React from "react";
import Modal from "react-bootstrap/Modal";

export const InstallModal = ({show, toggle, modal, setShow}) => {
    return (
        <div>
            <div>
                <title>weBoost Installed | Home Complete</title>
                <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossOrigin="anonymous"
                />
            </div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-error"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Body>
                    <div className="modal-close">
                        <a href="#" className="close-x" onClick={toggle}></a>
                    </div>
                    <div className="body">
                        <img alt="" src={modal.img}/>
                        <p className="title">{modal.title}</p>
                        <p>
                            <span dangerouslySetInnerHTML={{__html: modal.body}}/>
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
