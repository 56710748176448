import React, {useState, useEffect} from 'react';
import {InstallModal} from "./InstallModal";
import './Office.css';
import {ScaleLoader} from "react-spinners"


function Office() {
    const axios = require('axios');
    const [show400, setShow400] = useState(false);
    const [show409, setShow409] = useState(false);
    const [show200, setShow200] = useState(false);
    const [code, setCode] = useState(null);
    const [dirty, setDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (dirty) {
            validateCode();
        }
    }, [code]);

    const modal_409 = {
        title: 'This code has already been redeemed.',
        img: 'https://assets.wilsonelectronics.com/m/68a7fa22d029b0b3/original/attention_icon-min.png',
        body: 'Please call the weBoost customer service hotline: <b>1-866-294-1660</b> for assistance.<br/>'
    };
    const modal_404 = {
        title: 'The code you entered is invalid. Try again.',
        img: 'https://assets.wilsonelectronics.com/m/68a7fa22d029b0b3/original/attention_icon-min.png',
        body: 'Please verify you are using the code from your installation card and ENTER again. If the code you enter is still invalid, call the weBoost customer service hotline:<br/><b>1-866-294-1660</b>.'
    };
    let modal_200 = {
        title: 'You are being redirected',
        img: 'https://assets.wilsonelectronics.com/m/784cbc0dfbb3c034/original/redirect_icon-min.png',
        body: 'We are now taking you to the page to schedule your installation. If you are not redirected within the next five seconds, please click on this <a href="https://www.ontechsmartservices.com/products/weboost-installed-office-300-hdspcl">link</a> to visit the installation landing page.'
    };

    function setClass(id, className) {
        document.getElementById(id).className = className;
    }

    const errTxt = 'error-text', errInp = 'error-input', errTxtHd = 'error-text-hide', errInpHd = 'error-input-hide';

    //validate redemption code
    function validateCode() {
        const codeText = 'code-error-text-msg', codeInput = 'code-error-input-msg';
        const cl = code?.length;
        const isCodeValid = (code && !isNaN(code) && ([9].indexOf(cl) > -1));
        setClass(codeText, isCodeValid ? errTxtHd : errTxt);
        setClass(codeInput, isCodeValid ? errInpHd : errInp);
        return isCodeValid;
    }

    function goToInstalled() {
        window.location.replace("https://www.weboost.com/boosters/business-signal-boosters");
    }

    function goToScheduled() {
        // window.location.replace("https://www.ontechsmartservices.com/products/weboost-installed-office-200-hdspcl");
    }

    function validateAndRedeem() {
        setDirty(true);
        if (validateCode()) {
            setIsLoading(true);
            handleSearch();
        }
    }

    const handleSearch = async () => {
        await axios.put(`${process.env.REACT_APP_API_URL}/redeem`, {zip_code: '00000', serial_id: code})
            .then(() => {
                setShow200(true);
                setTimeout(function () {
                    goToScheduled();
                }, 3000);
            })
            .catch(function (error) {
                if (undefined !== error && error.response) {
                    let resCode = error.response.status;
                    switch (resCode) {
                        case (409):
                            setShow409(true);
                            return;
                        case (400):
                            setShow400(true);
                            return;
                        case (500):
                            return;
                        default:
                            setShow400(true);
                            return;
                    }
                }
                setShow400(true);
                return Promise.reject(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    function toggle409() {
        setShow409(false)
    }

    function toggle400() {
        setShow400(false)
    }

    function toggle200() {
        setShow200(false)
    }

    function addCode(evt) {
        setCode(evt.target.value);
        if (dirty) {
            validateCode();
        }
    }


    return (
        <>
            <div>
                <title>weBoost Installed | Home Complete</title>
                <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossOrigin="anonymous"
                />
                <link
                    rel="canonical"
                    href='https://www.WilsonElectronics.com/installation'
                />
            </div>

            <div className="page">
                <InstallModal show={show409} toggle={toggle409} modal={modal_409} setShow={setShow409}/>
                <InstallModal show={show400} toggle={toggle400} modal={modal_404} setShow={setShow400}/>
                <InstallModal show={show200} toggle={toggle200} modal={modal_200} setShow={setShow200}/>
                <div className="installation-body">
                    <header>
                        <div className="header-contents">
                            <img alt="" src="https://assets.wilsonelectronics.com/m/28470da341e166a2/original/weBoost_logo-min.png"/>
                            <div className="h-body">
                                <div className="mobile">Congratulations on your recent 
                                    <span className="new-line bold"> weBoost Office</span>
                                    purchase
                                </div>
                                <div className="non-mobile">
                                    Congratulations on your recent <span className='bold new-line'>weBoost Office purchase</span>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="divider"></div>
                    <div className="product-image">
                        <div className="non-mobile">
                            <img alt="" className="home-complete-img" src="https://assets.wilsonelectronics.com/m/40de449f02ee06e2/original/product_hero-2x-min.png"/>
                        </div>
                    </div>
                    <div className="icontainer">
                        <div className="redeem">
                            <div className="inputs">
                                <div className="enter-code">
                                    <div className="left-align"><b>Enter Your Install Code</b></div>
                                    <div className="code-input">
                                        <input id="code-error-input-msg" className="error-input-hide" placeholder="Install Code" list="results" type="text" name="search" onChange={addCode}/>
                                    </div>
                                    <div id="code-error-text-msg" className="error-text-hide">Enter your 9-digit install code</div>
                                    <div className="code-input">
                                        {isLoading ?
                                            <div id="loadingContainer">
                                                <ScaleLoader color={"#cd0000"}/>
                                            </div> :
                                            <button onClick={validateAndRedeem}>Submit</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="info">
                                <p>To schedule your professional installation, enter the <b>INSTALL CODE* </b>found on the installation card inside your weBoost office product packaging. After your install code is verified, you will be redirected to OnTech Smart Services to finalize the scheduling process. PLEASE NOTE: Once you click SUBMIT your install code will be redeemed, and you must complete the scheduling process.*Install code valid for one-time use only.
                                </p>
                                <p>
                                    <b>Please Note:</b> Once you click SUBMIT your install code will be redeemed, and you must complete the scheduling process. *Install code valid for one-time use only. If you’re not ready to continue the process at that time, simply revisit the page later. Once your code has been verified, you’ll be redirected to OnTech Smart Services to finalize your scheduling process.
                                </p>
                                <p>
                                    For your installation appointment, please make sure the installer has access to the facility where the product is to be installed, as well as approval to access the roof.
                                </p>
                                <p className="small">
                                    *Install code valid for one-time use only.
                                </p>
                            </div>
                        </div>
                        <hr className="non-mobile"/>
                        <div className="how-to-find">
                            <section>
                                Where to Find Your Installation Card
                            </section>
                            <img alt="" src="https://assets.wilsonelectronics.com/m/7fd97f7946c4bd9a/original/step_by_step-2x-min.png"/>
                        </div>
                        <hr className="mobile"/>
                        <div className="onTech-bg">
                            <div className="onTech">
                                <img alt="" className="onTech-logo" src="https://assets.wilsonelectronics.com/m/47a526e39b71c15a/original/OnTech_001-min.png"/>
                                <p>
                                    <b>OnTech</b> is a trusted weBoost partner whose technicians are trained and insured to fulfill our installation requests nationwide.
                                </p>
                                <div className="onTech-rep mobile">
                                    <img alt="" src="https://assets.wilsonelectronics.com/m/33814e1bdfed8ecc/original/installer_ls_mobile-min.png"/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default Office;