import React from "react";
import Home from "./Home";
import Office from "./Office";

function App() {
	const office = window.location.pathname.indexOf("office") > -1;
	return (<div>{office ? <Office /> : <Home />}</div>);
}

export default App;
