import React, { useState, useEffect } from "react";
import { InstallModal } from "./InstallModal";
import "./Home.css";
import { ScaleLoader } from "react-spinners";

function Home() {
	const axios = require("axios");
	const [show400, setShow400] = useState(false);
	const [show409, setShow409] = useState(false);
	const [show200, setShow200] = useState(false);
	const [zip, setZip] = useState(null);
	const [code, setCode] = useState(null);
	const [dirty, setDirty] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (dirty) {
			validateZip();
		}
	}, [zip]);
	useEffect(() => {
		if (dirty) {
			validateCode();
		}
	}, [code]);

	const modal_409 = {
		title: "This code has already been redeemed.",
		img: "https://assets.wilsonelectronics.com/m/68a7fa22d029b0b3/original/attention_icon-min.png",
		body: "Please call the weBoost customer service hotline: <b>1-866-294-1660</b> for assistance.<br/>",
	};
	const modal_404 = {
		title: "The code you entered is invalid. Try again.",
		img: "https://assets.wilsonelectronics.com/m/68a7fa22d029b0b3/original/attention_icon-min.png",
		body: "Please verify you are using the code from your installation card and ENTER again. If the code you enter is still invalid, call the weBoost customer service hotline:<br/><b>1-866-294-1660</b>.",
	};
	let modal_200 = {
		title: "You are being redirected",
		img: "https://assets.wilsonelectronics.com/m/784cbc0dfbb3c034/original/redirect_icon-min.png",
		body: 'We are now taking you to the page to schedule your installation. If you are not redirected within the next five seconds, please click on this <a href="https://www.ontechsmartservices.com/products/weboost-installed-home-complete">link</a> to visit the installation landing page.',
	};

	function setClass(id, className) {
		document.getElementById(id).className = className;
	}

	// zip codes 38980 and 83900-8789 will pass validation. However, 83900- or 839008789 will not pass our validation test.
	function validZC(elementValue) {
		let zipCodePattern = /^\d{5}$|^\d{5}-\d{4}$/;
		let zz = zipCodePattern.test(elementValue);
		return zz;
	}

	const errTxt = "error-text",
		errInp = "error-input",
		errTxtHd = "error-text-hide",
		errInpHd = "error-input-hide";

	//validate zip code
	function validateZip() {
		const zipText = "zip-error-text-msg",
			zipInput = "zip-error-input-msg";
		const isZipCodeValid = zip !== undefined && validZC(zip);
		setClass(zipText, isZipCodeValid ? errTxtHd : errTxt);
		setClass(zipInput, isZipCodeValid ? errInpHd : errInp);
		return isZipCodeValid;
	}

	//validate redemption code
	function validateCode() {
		const codeText = "code-error-text-msg",
			codeInput = "code-error-input-msg";
		const cl = code?.length;
		const isCodeValid = code && !isNaN(code) && [7, 9].indexOf(cl) > -1;
		setClass(codeText, isCodeValid ? errTxtHd : errTxt);
		setClass(codeInput, isCodeValid ? errInpHd : errInp);
		return isCodeValid;
	}

	function goToInstalled() {
		window.location.replace("https://www.weboost.com/products/weboost-installed-home-complete");
	}

	function goToScheduled() {
		window.location.replace("https://www.ontechsmartservices.com/products/weboost-installed-home-complete-hdspcl");
	}

	function validateAndRedeem() {
		setDirty(true);
		let vz = validateZip();
		let vc = validateCode();
		if (vz && vc) {
			setIsLoading(true);
			handleSearch();
		}
	}

	const handleSearch = async () => {
		await axios
			.put(`${process.env.REACT_APP_API_URL}/redeem`, { zip_code: zip, serial_id: code })
			.then(() => {
				setShow200(true);
				setTimeout(function () {
					goToScheduled();
				}, 3000);
			})
			.catch(function (error) {
				if (undefined !== error && error.response) {
					let resCode = error.response.status;
					switch (resCode) {
						case 409:
							setShow409(true);
							return;
						case 400:
							setShow400(true);
							return;
						case 500:
							return;
						default:
							setShow400(true);
							return;
					}
				}
				setShow400(true);
				return Promise.reject(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	function toggle409() {
		setShow409(false);
	}

	function toggle400() {
		setShow400(false);
	}

	function toggle200() {
		setShow200(false);
	}

	function addZip(evt) {
		setZip(evt.target.value);
	}

	function addCode(evt) {
		setCode(evt.target.value);
		if (dirty) {
			validateCode();
		}
	}

	return (
		<>
			<div>
				<title>weBoost Installed | Home Complete</title>
				<link
					rel="stylesheet"
					href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
					integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
					crossOrigin="anonymous"
				/>
				<link rel="canonical" href="https://www.WilsonElectronics.com/installation" />
			</div>

			<div className="page">
				<InstallModal show={show409} toggle={toggle409} modal={modal_409} setShow={setShow409} />
				<InstallModal show={show400} toggle={toggle400} modal={modal_404} setShow={setShow400} />
				<InstallModal show={show200} toggle={toggle200} modal={modal_200} setShow={setShow200} />
				<div className="installation-body">
					<header className="header-home">
						<div className="header-contents">
							<img alt="" src="https://assets.wilsonelectronics.com/m/28470da341e166a2/original/weBoost_logo-min.png" />
							<div className="h-body">
								<div className="mobile">
									Congratulations on your
									<span className="new-line"> recent purchase of</span>
									<span className="new-line bold"> weBoost Installed</span>
									<span className="new-line bold"> Home Complete.</span>
								</div>
								<div className="non-mobile">
									Congratulations on your recent purchase
									<span className="new-line">
										{" "}
										of <span className="bold"> weBoost Installed | Home Complete. </span>{" "}
									</span>
								</div>
							</div>
						</div>
					</header>
					<div className="product-image">
						<div className="non-mobile">
							<img
								alt=""
								className="home-complete-img"
								src="https://assets.wilsonelectronics.com/m/769cf3fe9bc51aed/original/product_001-min.png"
							/>
						</div>
					</div>
					<div className="icontainer">
						<div className="redeem">
							<div className="inputs">
								<div className="enter-code">
									<b>Where to install? Enter Your zip code</b>
									<div className="code-input">
										<input
											id="zip-error-input-msg"
											className="error-input-hide"
											placeholder="84121"
											list="results"
											type="text"
											name="search"
											onChange={addZip}
										/>
									</div>
									<div className="error-text-hide" id="zip-error-text-msg">
										Enter the 5-digit zip code of the installation residence
									</div>
								</div>
								<br />
								<div className="enter-code">
									<b>Enter Your Install Code</b>
									<div className="code-input">
										<input
											id="code-error-input-msg"
											className="error-input-hide"
											placeholder="Install Code"
											list="results"
											type="text"
											name="search"
											onChange={addCode}
										/>
									</div>
									<div id="code-error-text-msg" className="error-text-hide">
										Enter your 7 or 9 digit install code
									</div>
									<div className="code-input">
										{isLoading ? (
											<div id="loadingContainer">
												<ScaleLoader color={"#cd0000"} />
											</div>
										) : (
											<button onClick={validateAndRedeem}>Submit</button>
										)}
									</div>
								</div>
							</div>
							<div className="info">
								<p>
									To schedule the professional installation of your weBoost Home Complete, please <b>enter the install code*</b>{" "}
									located on the installation card found inside the product packaging.
								</p>
								<p>
									<b>Please Note:</b> Once you click “submit,” your install code will be redeemed and you must complete the
									scheduling process. If you’re not ready to continue the process now, revisit this page later. After your code has
									been verified, you’ll be redirected to OnTech Smart Services to finalize your scheduling process.{" "}
								</p>
								<p className="small">* The install code you receive with purchase is only valid for one-time use.</p>
							</div>
						</div>
						<hr className="non-mobile" />
						<div className="how-to-find">
							<section>How to Find Your Installation Card</section>
							<img alt="" src="https://assets.wilsonelectronics.com/m/604d570c0454b99d/original/step_by_step_001-min.png" />
						</div>
						<hr className="mobile" />
						<div className="onTech-bg">
							<div className="onTech">
								<img
									alt=""
									className="onTech-logo"
									src="https://assets.wilsonelectronics.com/m/47a526e39b71c15a/original/OnTech_001-min.png"
								/>
								<p>
									<b>OnTech</b> is a trusted weBoost partner whose technicians are trained and insured to fulfill our installation
									requests nationwide.
								</p>
								<div className="onTech-rep mobile">
									<img alt="" src="https://assets.wilsonelectronics.com/m/33814e1bdfed8ecc/original/installer_ls_mobile-min.png" />
								</div>
							</div>
						</div>
						<div className="purchase">
							<p className="mobile">
								Interested in <br />
								purchasing a<br />
								weBoost Installed
								<br />
								Home Complete?
							</p>
							<div className="mobile">
								<button onClick={goToInstalled}>Shop now</button>
							</div>
							<div className="purchase-body non-mobile">
								<div className="left">
									Interested in purchasing a <span className="new-line">weBoost Installed | Home Complete?</span>
								</div>
								<div className="right">
									<button onClick={goToInstalled}>Shop now</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Home;
